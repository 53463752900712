/* You can add global styles to this file, and also import other style files */
/**
 *
 #0F2263 very dk blue
 */
 :root {
    --primary-hue: 43;
    --primary-saturation: 74%;
    --primary-lightness: 49%;

    /* --secondary-hue: 266;
    --secondary-saturation: 74%;
    --secondary-lightness: 49%; */
    --secondary-hue: 120;
    --secondary-saturation: 100%;
    --secondary-lightness: 20%;


    /* --contrast: hsl(266, 74%, 49%); */

    --primary-light: hsl(var(--primary-hue) var(--primary-saturation) var(--primary-lightness));
    --secondary-light: hsl(var(--secondary-hue) var(--secondary-saturation) var(--secondary-lightness));
    --text1-light: hsl(var(--primary-hue) var(--primary-saturation) 9%);
    --text2-light: hsl(var(--primary-hue) 50% 11%);
    /* --text3-light: hsl(var(--secondary-hue) var(--secondary-saturation) 30%); */
    --text3-light: hsl(var(--secondary-hue) var(--secondary-saturation) 20%);
    --surface1-light: hsl(var(--primary-hue) 50% 94%);
    --surface2-light: hsl(var(--primary-hue) 50% 98%);
    /* --surface3-light: hsl(var(--secondary-hue) 20% 92%); */
    --surface3-light: hsl(var(--secondary-hue) 20% 95%);
    --surface4-light: hsl(var(--primary-hue) var(--primary-saturation) 85%);
    --surface5-light: hsl(var(--secondary-hue) 50% 75%);
    --surface6-light: hsl(var(--secondary-hue) 50% 50%);
    --surface-shadow-light: var(--primary-hue) 10% 20%;
    --shadow-strength-light: .02;

    /* --color-bg-lt: rgb(252, 250, 230);
    --color-bg-med: rgba(218, 165, 32, 0.3);
    --color-bg-dk: hsl(43, 74%, 49%);
    --color-action-dk: rgb(89, 60, 143);
    --color-action-dk2: rgb(54, 36, 86);
    --color-action-warn: rgb(178, 34, 34);
    --color-action-warn-dk: rgb(120, 44, 2);
    --color-text-alt: hsl(43, 89%, 38%); */



    color-scheme: light;
    --primary: var(--primary-light);
    --secondary: var(--secondary-light);
    --text1: var(--text1-light);
    --text2: var(--text2-light);
    --text3: var(--text3-light);
    --surface1: var(--surface1-light);
    --surface2: var(--surface2-light);
    --surface3: var(--surface3-light);
    --surface4: var(--surface4-light);
    --surface5: var(--surface5-light);
    --surface6: var(--surface6-light);
    --surface-shadow: var(--surface-shadow-light);
    --shadow-strength: var(--shadow-strength-light);
}
/*
@media (prefers-color-scheme: dark) {
    :root {
      color-scheme: dark;

      --primary: var(--primary-dark);
      --text1: var(--text1-dark);
      --text2: var(--text2-dark);
      --surface1: var(--surface1-dark);
      --surface2: var(--surface2-dark);
      --surface3: var(--surface3-dark);
      --surface4: var(--surface4-dark);
      --surface-shadow: var(--surface-shadow-dark);
      --shadow-strength: var(--shadow-strength-dark);
    }
}
*/
* {
    box-sizing: border-box;
}
body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--surface2);
    color: var(--text2);
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas: "header header"
        "main main"
        "footer footer";
    grid-template-rows: min-content auto min-content;
    height: 100vh;
    width: 100%; /** Instead of 100vw because of scroll bars...?  */
    overflow-x: hidden;
}
a, a:visited {
    color: var(--text3);
    text-decoration-style: double;
}


button {
    background-color: var(--surface4);
    border: 2px solid transparent;
    border-radius: 5px;
    font-size: .9rem;
    padding: .5rem 1rem;
    cursor: pointer;
}
button.btn-sm {
    font-size: .7rem;
    padding: .125rem .25rem;
}
header button, summary button, th button {
    padding: .25rem .5rem;
}
button:hover, button:focus {
    border: 2px dashed var(--primary);
}
button:focus {
    outline-offset: 2px;
}
button + button {
    margin-left: 1rem;
}
h2 + button {
    margin-left: 1rem;
}
button[type=submit] {
    background-color: var(--surface5);
    border-color: transparent;
    color: var(--text1);
}
button[type=submit]:hover, button[type=submit]:focus {
    background-color: var(--surface6);
    border: 2px dashed var(--secondary);
}
button.btn-delete {
    background-color: hsl(17 74% 40%);
    color: var(--surface2);
}
button.btn-delete:hover, button.btn-delete:focus {
    border-color: var(--text1);
}
button.btn-reroll {
    background-color: transparent;
    font-size: 2rem;
    border: none;
    padding: 0 1rem;
    margin: 0;
    cursor: pointer;
}


h1, h2, h3, h4, h5, h6 {
    color: var(--text1);
    font-weight: bold;
    font-family: serif;
}

dt {
    font-weight: bold;
}

details {
    border-radius: 4px;
    padding: .5rem;
    background-color: var(--surface2);
    border: 1px solid var(--surface4);
}
summary {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0rem;
    align-items: center;
}
details summary #summary-title::before {
    content: "▲";
    margin-right: .5rem;
    font-size: .8rem;
    color: var(--text1);
}
details[open] summary #summary-title::before {
    content: "▼";
    color: var(--text1);
}
summary #summary-title {
    font-weight: bold;
    font-size: 1rem;
    color: var(--text1);
}
details .body {
    margin-top: .5rem;
}

form {
    margin-bottom: 1rem;
}
.formField {
    margin-bottom: 1rem;
}

label {
    display: block;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: .25rem;
}
input, textarea, select {
    display: block;
    font-family: inherit;
    width: 100%;
    font-size: 1rem;
    padding: .5rem;
    border-radius: 5px;
    border: 1px solid var(--secondary);
}
input[type="color"] {
    padding: .125rem;
}
fieldset {
    border: none;
    border-bottom: 1px solid var(--secondary);
    margin-bottom: 1rem;
}
div.fieldReroll {
    display: flex;
}
div.fieldReroll input, div.fieldReroll textarea, div.fieldReroll select {
    border-radius: 5px 0px 0px 5px;
}
div.fieldReroll button.btn-reroll {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid var(--secondary);
    border-left-width: 0;
    background-color: var(--surface5);
}


main {
    grid-area: main;
    padding: 1rem;
    position: relative;
    background-color: var(--surface1);
}

.home-page {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas: "leftColumn notes";
    gap: 1rem;
}
#leftColumn {
    grid-area: leftColumn;
}

#town {
    grid-area: town;
}

#npcs {
    grid-area: npcs;
}

#notes {
    grid-area: notes;
    padding: 0 1rem;
}

#pageContent > .text-page {
    max-width: 50rem;
    display: block;
    margin: 0 auto;
}

.text-page section > section {
    border-bottom: 1px solid var(--surface5);
    margin-bottom: 1rem;
}


.rules-page {
    display: grid;
    max-width: 80rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "header header"
    "creation resolution"
    "social injury";
    margin: 0 auto;
    column-gap: 2rem;
}

.rules-page h2#rulesheader {
    grid-area: header;
}

.rules-page section {
    max-width: 40rem;
}
.rules-page section#creation {
    grid-area: creation;
}
.rules-page section#resolution {
    grid-area: resolution;
}
.rules-page section#social {
    grid-area: social;
}
.rules-page section#injury {
    grid-area: injury;
}

.rules-page ol {
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
}
.rules-page ol li:before {
    content: counters(item, ".") ".";
    counter-increment: item;
    font-weight: bold;
    margin-right: 0.5rem;
}

.rules-page ol > li {
    font-size: 1.1rem;
    margin-bottom: .5rem;
}

.rules-page ol ol {
    margin-top: .5rem;
    padding-left: 2rem;
}

.rules-page ol ol > li {
    font-size: 1rem;
}

table {
    border-collapse: collapse;
    border: none;
    border-radius: 5px;
    background-color: var(--surface2);
}
table td, table th {
    padding: .25rem;
    border: none;
}
table thead tr {
    background-color: var(--primary);
    font-weight: bold;
}

/* border radius only works on cells, so this complication to soften the edges. */
table thead tr th:first-child {
    border-top-left-radius: 5px;
}
table thead tr th:last-child {
    border-top-right-radius: 5px;
}
table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
}
table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
}

table tbody tr:nth-child(even) {
    background-color: var(--surface1);
}



/**
 * 1. Make the dialog container, and its child overlay spread across
 *    the entire window.
 */
 .dialog-container,
 .dialog-overlay {
   position: fixed; /* 1 */
   top: 0; /* 1 */
   right: 0; /* 1 */
   bottom: 0; /* 1 */
   left: 0; /* 1 */
 }

 /**
  * 1. Make sure the dialog container and all its descendants sits on
  *    top of the rest of the page.
  * 2. Make the dialog container a flex container to easily center the
  *    dialog.
  */
 .dialog-container {
   z-index: 200; /* 1 */
   display: flex; /* 2 */
 }

 /**
  * 1. Make sure the dialog container and all its descendants are not
  *    visible and not focusable when it is hidden.
  */
 .dialog-container[aria-hidden='true'] {
   display: none; /* 1 */
 }

 /**
  * 1. Make the overlay look like an overlay.
  */
 .dialog-overlay {
   background-color: rgba(43, 46, 56, 0.9); /* 1 */
 }

 /**
  * 1. Vertically and horizontally center the dialog in the page.
  * 2. Make sure the dialog sits on top of the overlay.
  * 3. Make sure the dialog has an opaque background.
  */
 .dialog-content {
   margin: auto; /* 1 */
   z-index: 2; /* 2 */
   position: relative; /* 2 */
   background-color: white; /* 3 */
   min-width: 50vw;
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 0.5rem;
    padding: 1rem;
    overflow: auto;
}

.dialog-content header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.dialog-content header h1 {
    margin: 0;
}

.dialog-body p.rtable-result {
    display: block;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--primary);
}

.dialog-body form {
    margin-top: 2rem;
}

/**
    If this is in the component, the transition triggers late and you see the draw close on page load.
*/
had-tabledrawer {
    visibility: hidden; /* Keeps from focusing inside. */
    transform: translate(100%, 0);
    transition: all 0.4s ease-in-out;
}
had-tabledrawer[aria-expanded=true] {
    transform: none;
    visibility: visible; /* Allows focus when open */
}
